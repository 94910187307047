import ApiService from './service';
import * as http from './http';

export class FreshnessService extends ApiService {
    async getPieGraph(data) {
        const url = `${this.apiDomain}/api/freshnessDashboard/piegraph`;
        return http.post(url, data, this.store);
    }

    async getDailyData(data) {
        const url = `${this.apiDomain}/api/freshnessDashboard/daily`;
        return http.post(url, data, this.store);
    }

    async getWeeklyData(data) {
        const url = `${this.apiDomain}/api/freshnessDashboard/weekly`;
        return http.post(url, data, this.store);
    }

    async getMonthlyData(data) {
        const url = `${this.apiDomain}/api/freshnessDashboard/monthly`;
        return http.post(url, data, this.store);
    }

    async getAttributes() {
        const url = `${this.apiDomain}/api/freshnessDashboard/attributes`;
        return http.get(url, this.store);
    }

    async getDailyStatus() {
        const url = `${this.apiDomain}/api/freshnessDashboard/dailystatus`;
        return http.get(url, this.store);
    }

    async getWeeklyStatus() {
        const url = `${this.apiDomain}/api/freshnessDashboard/weeklystatus`;
        return http.get(url, this.store);
    }

    async getMonthlyStatus() {
        const url = `${this.apiDomain}/api/freshnessDashboard/monthlystatus`;
        return http.get(url, this.store);
    }

    async getStatusHeading() {
        const url = `${this.apiDomain}/api/freshnessDashboard/statusheading`;
        return http.get(url, this.store);
    }
}
