import React, { useEffect } from 'react';
import { useState } from 'react';
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import RenderOffCanvas from '../offcanvas/offcanvas';
import { FaBars, FaBell, FaUser } from 'react-icons/fa';
import './navbar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AzureLogout } from '../../containers/auth/AzureLogin';
import Notification from '../notification/Notification';
import { useRef } from 'react';
import api from '../../api';
// import api from '../../api';

function Navigationbar() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [showNotifications, setShowNotifications] = useState(false);
    const [storeNotifications, setStoreNotifications] = useState([]);
    const [notificationsIsLoading, setNotificationsIsLoading] = useState(false);
    // const [active, setActive] = useState(false);

    // const theme = useSelector((state) => state.themesSettings);
    const authState = useSelector((state) => state.authUser);
    const notificationModalRef = useRef(null);
    const [shake, setShake] = useState(false);
    const closeModal = () => {
        setShowNotifications(false);
    };

    // Function to fetch notifications
    const fetchNotifications = async () => {
        setNotificationsIsLoading(true);
        try {
            const response =
                await api.userNotification.getNotificationsByUserId(
                    authState?.profile?.user_id,
                );

            if (response.data.status === 200) {
                setStoreNotifications(response.data.data.res);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setNotificationsIsLoading(false);
        }
    };

    // Load notifications when the component mounts
    useEffect(() => {
        fetchNotifications();
    }, [location.pathname]);

    // Handle click on notification icon (only toggles visibility now)
    const handleClickonNotificationIcon = () => {
        setShowNotifications(!showNotifications);
    };

    useEffect(() => {
        if (storeNotifications.length > 0) {
            const interval = setInterval(() => {
                setShake(true);
                setTimeout(() => setShake(false), 2000);
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [storeNotifications]);

    // Debug state updates

    // Close the modal when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                notificationModalRef.current &&
                !notificationModalRef.current.contains(event.target)
            ) {
                closeModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            <Navbar
                variant="light"
                className=" "
                style={{
                    background: '#ffffff',
                }}
            >
                <Container className="nav-style">
                    <Navbar.Brand
                        className="brand fw-bolder"
                        onClick={() => navigate('/')}
                    >
                        <h4>
                            <span className="fw-bolder" id="brandpre">
                                Data
                            </span>{' '}
                            <span className="fw-bolder">Observability</span>
                        </h4>
                    </Navbar.Brand>
                </Container>

                <Nav className="me-4">
                    <div className="d-md-flex d-md-block d-none">
                        <div
                            onClick={handleClickonNotificationIcon}
                            className={`p-0 position-relative m-1 notification-bell ${
                                shake ? 'shake' : ''
                            }`}
                        >
                            {/* Notification Dot */}
                            {storeNotifications.length > 0 && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '0px',
                                        right: '9px',
                                        width: '8px',
                                        height: '8px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                    }}
                                ></span>
                            )}
                            {/* Bell Icon */}
                            <FaBell size={'1.4em'} className="m-1 me-2" />
                        </div>

                        <div className="p-0 position-relative m-1">
                            <FaUser size={'1.4em'} className="m-1" />
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle variant="white">
                                {authState.profile.display_name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                <Dropdown.Item href="#/action-1">
                                    <AzureLogout />
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <FaBars
                        size={'1.4em'}
                        className="d-md-none d-sm-block"
                        onClick={handleShow}
                    />
                </Nav>
            </Navbar>
            <RenderOffCanvas
                name="side bar"
                handleClose={handleClose}
                show={show}
                placement={'end'}
            />
            {showNotifications && (
                <Notification
                    handleClickonNotificationIcon={
                        handleClickonNotificationIcon
                    }
                    notificationModalRef={notificationModalRef}
                    setShowNotifications={setShowNotifications}
                    showNotifications={showNotifications}
                    storeNotifications={storeNotifications}
                    notificationsIsLoading={notificationsIsLoading}
                />
            )}
        </>
    );
}

export default Navigationbar;
